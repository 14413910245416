a.a-btn{
    &.btn-red{
        background: #ff5747; /* Old browsers */
        background: -moz-linear-gradient(left, #ff5747 0%, #ff4757 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, #ff5747 0%,#ff4757 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, #ff5747 0%,#ff4757 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5747', endColorstr='#ff4757',GradientType=1 ); /* IE6-9 */
        color: $white;
        box-shadow: 0px 0px 28px 0px rgba(185,9,9,.25);
        display: inline-block;
        float: none;
        height: 6.4rem;
        line-height: 5.9rem;
        font-size: 1.8rem;
        letter-spacing: .01em;
        width: auto;
        padding: 0 5.5rem;
        border-radius: 3.3rem;
        text-transform: none;
    }
    &.right-arrow{
        padding-right: 9.5rem;
        &:after{
            display: block;
            position: absolute;
            content: '';
            right: 5.5rem;
            margin: auto;
            top: 0;
            bottom: 0;
            height: 15px;
            width: 28px;
            background: url(../imgs/icons/btn-arrow-right.png) no-repeat;
            background-size: contain;
            transition: .3s;
        }
        &:hover:after{
            transform: translateX(2rem);
        }
    }
    &.btn-yt {
        padding-left: 8rem;
        background: #e14f42 url(../imgs/icons/youtube.png) no-repeat 2rem center;
        background-size: auto 90%;
    }
}
a.a-btn-app{
    color: #8d8d8d;
    height: 10rem;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: .15em;
    line-height: 4rem;
    padding: 3rem 1rem 3rem 5.5rem;
    text-transform: uppercase;
    float: left;
    width: auto;
    position: relative;
    &.android{
        background: url(../imgs/icons/android.png) no-repeat left;
        background-size: 30px auto;
        margin-left: 3rem;
    }
    &.apple{
        background: url(../imgs/icons/apple.png) no-repeat left;
        background-size: 31px auto;
    }
}
.resta .a-scroll-down,
.page .a-scroll-down {
    height: 10rem;
    width: 10rem;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    cursor: pointer;
    &:after{
        position: absolute;
        display: block;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        height: 1.9rem;
        width: 1.9rem;
        border-right: 2px solid $white;
        border-bottom: 2px solid $white;
        transform: rotate(45deg);
    }
}
.a-image{
    float: left;
    width: 100%;
    position: relative;
    img{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }
}
.o-tiles .m-tile .a-image, .o-content-switch .a-image{
    height: 15.5rem;
}
body.hp h3.a-bottom-line,
.page .a-bottom-line,
.text-page .a-bottom-line {
    padding-bottom: 3.5rem;
    &:after{
        display: block;
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        margin: auto;
        height: 2px;
        width: 47px;
        background: #ff5747; /* Old browsers */
        background: -moz-linear-gradient(left, #ff5747 0%, #ff4757 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, #ff5747 0%,#ff4757 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, #ff5747 0%,#ff4757 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5747', endColorstr='#ff4757',GradientType=1 ); /* IE6-9 */
    }
    &.centered{
        text-align: center;
        &:after{
            right: 0;
        }
    }
    &+ p{
        margin-top: 2.8rem;
        font-size: 1.8rem;
        line-height: 1.7;
        letter-spacing: .02em;
    }
}
.a-left-line {
    font-size: 5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 3.5rem;
    &:before {
        display: block;
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        margin: auto;
        height: 100%;
        width: 2px;
        background: #ff5747; /* Old browsers */
        background: -moz-linear-gradient(left, #ff5747 0%, #ff4757 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, #ff5747 0%,#ff4757 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, #ff5747 0%,#ff4757 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5747', endColorstr='#ff4757',GradientType=1 ); /* IE6-9 */
    }
}
.a-without-line {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    text-align: center !important;
}
.m-footer-links .a-separator{
    display: inline-block;
    height: 1.3rem;
    width: 1px;
    margin: 1.4rem 1rem 0;
    background: $white;
    float: left;
}
.o-tiles-slider .m-tile .a-image{
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    margin-right: 3rem;
    border: 4px solid $white;
    overflow: hidden;
    box-shadow: 8px 10px 35px 0px rgba(157,157,157,0.25);
    img{
        height: 100%;
        right: -9999px;
        left: -9999px;
    }
}
.a-mask{
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 15%;
    z-index: 1;
    &.right-mask{
        right: 0;
        background: -moz-linear-gradient(left,  rgba(252,249,250,0) 0%, rgba(252,249,250,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba(252,249,250,0) 0%,rgba(252,249,250,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  rgba(252,249,250,0) 0%,rgba(252,249,250,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fcf9fa', endColorstr='#fcf9fa',GradientType=1 ); /* IE6-9 */

    }
    &.left-mask{
        left: 0;
        background: -moz-linear-gradient(left, rgba(252,249,250,1) 0%, rgba(252,249,250,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(252,249,250,1) 0%,rgba(252,249,250,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(252,249,250,1) 0%,rgba(252,249,250,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcf9fa', endColorstr='#00fcf9fa',GradientType=1 ); /* IE6-9 */
    }
}
.a-leaf{
    position: absolute;
    z-index: -1;
    &.leaf2{
        mix-blend-mode: multiply;
    }
}

@media (max-width: $screen-md-max){
    .m-footer-links .a-separator{
        float: none;
    }
    body.resta{
        .part1 h2{
            padding-bottom: 3.5rem;
            &:after{
                display: block;
                position: absolute;
                content: '';
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
                height: 2px;
                width: 47px;
                background: #ff5747; /* Old browsers */
                background: -moz-linear-gradient(left, #ff5747 0%, #ff4757 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left, #ff5747 0%,#ff4757 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right, #ff5747 0%,#ff4757 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5747', endColorstr='#ff4757',GradientType=1 ); /* IE6-9 */
            }
        }
        .o-tiles .m-tile, .o-content-switch{
            .a-image{
                height: 130px;
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    a.a-btn.right-arrow{
        padding-right: 5.5rem;
        &:after{
            display: none;
        }
    }
    a.a-btn-app{
        height: 8rem;
        line-height: 2rem;
        padding-bottom: 0;
        &.android{
            margin: 0;
        }
    }
    .o-tiles-slider .m-tile{
        .m-top{
            .a-image{
                margin-right: 1rem;
            }
        }
        p{
            font-size: 1.5rem;
        }
    }
}