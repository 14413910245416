/* file */
input[type=file] {
    cursor: pointer;
}
.jcf-file {
    display: inline-block;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    background: transparent;
    .jcf-real-element {
        position: absolute;
        font-size: 200px;
        height: 200px;
        margin: 0;
        right: 0;
        top: 0;
    }
    .jcf-fake-input {
        display: none;
    }
    .jcf-upload-button {
        display: block;
        width: 18px;
        height: 16px;
        background: transparent url("../imgs/icons/upload-image.png") no-repeat center center;
        background-size: 18px auto;
        text-indent: -10000px;
    }
}