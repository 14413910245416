/* number input */
.jcf-number {
  display: inline-block;
  position: relative;
  height: 32px;
  input {
    -moz-appearance: textfield;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #777;
    padding: 3px 27px 3px 7px;
    margin: 0;
    height: 100%;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }
  .jcf-btn-dec,
  .jcf-btn-inc {
    position: absolute;
    background: #aaa;
    width: 20px;
    height: 15px;
    right: 1px;
    top: 1px;
    &:hover {
      background: #e6e6e6;
    }
    &:before {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -4px;
      border: 4px solid #aaa;
      border-color: transparent transparent #000 transparent;
    }
    &.jcf-disabled {
      opacity: 0.3;
    }
  }
  .jcf-btn-dec {
    top: auto;
    bottom: 1px;
    :before {
      margin: -1px 0 0 -4px;
      border-color: #000 transparent transparent transparent;
    }
  }
  &.jcf-disabled {
    .jcf-btn-dec,
    .jcf-btn-inc {
      &:hover {
        background: #aaa;
      }
      &:before {
        opacity: 0.3;
      }
    }
    input {
      background: #ddd;
    }
  }
}