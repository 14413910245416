body {
  &.login {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: transparent url("../imgs/login-background.png") no-repeat center center fixed !important;
    -webkit-background-size: cover;
    background-size: cover;
    label {
      text-transform: uppercase;
    }
    .content {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      .row {
        width: 100%;
      }
      .header {
        display: block;
        position: relative;
        top: 0;
        width: 108px;
        height: 62px;
        margin: 0 auto 15%;
        .logo {
          display: block;
          width: 108px;
          height: 62px;
          background: transparent url("../imgs/icons/brand-icon-white.png") no-repeat center center;
          -webkit-background-size: 100%;
          background-size: 100%;
          text-indent: -10000px;
        }
      }
      form {
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          -webkit-text-fill-color: #fff;
        }
        .form-group {
          position: relative;
          &,
          input,
          a {
            color: #fff;
          }
          input[type="password"] {
            padding-right: 70px;
          }
          .absolute {
            right: 10px;
            bottom: 7px;
          }
        }
      }
      p, a {
        color: #fff;
        text-transform: uppercase;
        font-weight: 400;
      }
      p {
        margin: 30px 0;
      }
      a {
        font-weight: 600;
        &.btn {
          margin-bottom: 10px;
        }
      }
    }
    #login-form-back {
      margin-top: 20px;
    }
  }
}