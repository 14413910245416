.jcf-textarea {
  border: 1px solid #b8c3c9;
  box-sizing: content-box;
  display: inline-block;
  position: relative;
  .jcf-scrollbar-horizontal {
    display: none;
    height: 0;
  }
  textarea {
    padding: 8px 10px;
    border: none;
    margin: 0;
  }
  .jcf-resize {
    position: absolute;
    text-align: center;
    cursor: se-resize;
    background: #e3e3e3;
    font-weight: bold;
    line-height: 15px;
    text-indent: 1px;
    font-size: 12px;
    height: 15px;
    width: 14px;
    bottom: 0;
    right: 0;
  }
  .jcf-resize:before {
    border: 1px solid #000;
    border-width: 0 1px 1px 0;
    display: block;
    margin: 4px 0 0 3px;
    width: 6px;
    height: 6px;
    content: '';
  }
}