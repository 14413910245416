//Colors
$red-default: #ff4747;
$red-active: #e54040;
$black: #212121;
$dark-gray: #757575;
$light-gray: #9e9e9e;
$blue-bg: #f7f9fa;
$white: #ffffff;
$gray-border: #f2f2f2;
$footer-bg: #474747;


::-webkit-input-placeholder {
    color: #9e9e9e;
    font-family: $font-source;
    font-size: 14px;
    font-weight: 300;
}

:-moz-placeholder { /* Firefox 18- */
    color: #9e9e9e;
    font-family: $font-source;
    font-size: 14px;
    font-weight: 300;
}

::-moz-placeholder {  /* Firefox 19+ */
    color: #9e9e9e;
    font-family: $font-source;
    font-size: 14px;
    font-weight: 300;
}

:-ms-input-placeholder {
    color: #9e9e9e;
    font-family: $font-source;
    font-size: 14px;
    font-weight: 300;
}

body{
    background-color: #f5f8fa;
    @media (min-width: $screen-sm-min) {
        background-color: #f7f9fa;
    }
    &.food{
        padding: 211px 0 75px 0;
        @include transition;
        &.sticky, &.landscape{
            padding-top: 100px;
            @media (min-width: $screen-md-min) {
                padding-top: 0;
            }
        }
        &.white-bg{
            background-color: $white;
            @media (min-width: $screen-sm-min) {
                background-color: #f7f9fa;
            }
        }
    }
    padding-bottom: 75px;
}

.container-fluid{
    padding-left: 0;
    padding-right: 0;
    @media (min-width: $screen-lg-min) {
        width: 1240px;
        margin: 0 auto;
    }
}

.container-fluid > .col-xs-12{
    padding-left: 0;
    padding-right: 0;
}

.list-inline > li{
    padding-right: 0;
    padding-left: 0;
}

.row{
    margin-left: 0;
    margin-right: 0;
}

@media (min-width: $screen-md-min) {
    .tabs-container,
    .food-detail{
        width: 60%;
    }
}

@media (min-width: $screen-lg-min) {
    .top-navigation{
        width: 100%;
        &__container{
            width: 1240px;
            margin: 0 auto;
        }
    }

    .food-search{
        width: 100%;
        &__container{
            width: 1240px;
            margin: 0 auto;
        }
    }

    .restaurant-header{
        width: 100%;
        &__container{
            width: 1240px;
            margin: 0 auto;
        }
    }
}