.form-control::-moz-placeholder {
    color: #212121;
    opacity: 1;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #fff !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active{
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #000;
}

input[type="text"],
input[type="password"],
input[type="tel"],
input[type="email"],
input[type="text"],
input[type="number"] {
    background: transparent;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    box-shadow: none;
    &:focus {
        box-shadow: none;
    }
}

input[type="radio"],
input[type="checkbox"] {
    cursor: pointer;
}

button:focus {
    outline: 0 !important;
}

label {
    cursor: pointer;
}

@import "form/radio";

@import "form/checkbox";

@import "form/file";

@import "form/select";

@import "form/number";

@import "form/range";

@import "form/textarea";

/* common styles */
.jcf-disabled {background: #ddd !important;}
.jcf-focus, .jcf-focus * {/*border-color: transparent !important;*/}

@import "form/switch";