/* select */
body > .jcf-select-drop {
  position: absolute;
  margin: -1px 0 0;
  z-index: 9999;
}
body > .jcf-select-drop.jcf-drop-flipped {
  margin: 1px 0 0;
}

.jcf-select {
  display: block;
  width: 100%;
  vertical-align: top;
  position: relative;
  border: 1px solid rgb(204, 204, 204);
  background: transparent;
  padding: 6px 12px;
  min-width: 150px;
  line-height: 21px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  select {
    z-index: 1;
    left: 0;
    top: 0;
  }
  .jcf-select-text {
    text-overflow:ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: default;
    display: block;
    font-size: 13px;
    line-height: 26px;
    margin: 0 35px 0 0;
  }
  .jcf-select-opener {
    position: absolute;
    text-align: center;
    background: transparent url("../imgs/icons/arrow-down.png") no-repeat center center;
    -webkit-background-size: 13px;
    background-size: 13px;
    width: 26px;
    bottom: 0;
    right: 0;
    top: 0;
  }
  .jcf-select-drop {
    position: absolute;
    margin-top: 0px;
    z-index: 9999;
    top: 100%;
    left: -1px;
    right: -1px;
  }
  .jcf-drop-flipped {
    bottom: 100%;
    top: auto;
  }
  &.jcf-compact-multiple {
    max-width: 220px;
    .jcf-select-opener{
      &:before {
        display: inline-block;
        padding-top: 2px;
        content: '...';
      }
    }
  }
}

.jcf-select-drop {
  .jcf-select-drop-content {
    border: 1px solid #f00;
  }
  &.jcf-compact-multiple {
    .jcf-hover {
      background: none;
    }
    .jcf-selected {
      background: #e6e6e6;
      color: #000;
      &:before {
        display: inline-block;
        content: '';
        height:4px;
        width:8px;
        margin:-7px 5px 0 -3px;
        border:3px solid #777;
        border-width:0 0 3px 3px;
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.7071067811865483, M12=0.7071067811865467, M21=-0.7071067811865467, M22=0.7071067811865483, SizingMethod='auto expand')";
      }
    }
  }
}