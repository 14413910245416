/* range input */
.jcf-range {
  display: inline-block;
  min-width: 200px;
  margin: 0 10px;
  width: 130px;
  &.jcf-vertical {
    min-width: 0;
    width: auto;
    .jcf-range-wrapper {
      margin: 0;
      width: 10px;
      height: auto;
      padding: 20px 0 0;
    }
    .jcf-range-track {
      height: 180px;
      width: 10px;
    }
    .jcf-range-handle {
      left: -5px;
      top: auto;
    }
    .jcf-range-display-wrapper {
      left: auto;
      right: auto;
      top: 10px;
      bottom: 10px;
    }
    .jcf-range-display.jcf-range-min {
      margin: 0 0 -10px;
      padding: 0 0 10px;
    }
    .jcf-range-display.jcf-range-max {
      margin: -10px 0 0;
      padding: 10px 0 0;
    }
    .jcf-range-mark {
      margin: 0 0 9px;
      left: 14px;
      top: auto;
      width: 3px;
      height: 1px;
    }
  }
  .jcf-range-track {
    margin: 0 20px 0 0;
    position: relative;
    display: block;
    height: 10px;
  }
  .jcf-range-wrapper {
    position: relative;
    background: #e5e5e5;
    border-radius: 5px;
    display: block;
    margin: 5px 0;
  }
  .jcf-range-display-wrapper {
    position: absolute;
    min-height: 10px;
    min-width: 10px;
    left: 10px;
    right: 10px;
  }
  .jcf-range-display {
    border-radius: 5px;
    position: absolute;
    background: #888;
    width: 10px;
    height: 10px;
    &.jcf-range-min {
      margin: 0 0 0 -10px;
      padding: 0 0 0 10px;
    }
    &jcf-range-max {
      margin: 0 -10px 0 0;
      padding: 0 10px 0 0;
    }
  }
  .jcf-range-handle {
    position: absolute;
    background: #aaa;
    border-radius: 19px;
    width: 19px;
    height: 19px;
    margin: -4px 0 0;
    z-index: 1;
    top: 0;
    left: 0;
  }
  .jcf-range-mark {
    position: absolute;
    overflow: hidden;
    background: #000;
    width: 1px;
    height: 3px;
    top: -7px;
    margin: 0 0 0 9px;
  }
  .jcf-active-handle {
    z-index: 2;
  }
  &.jcf-focus .jcf-active-handle {
    border: 1px solid #f00;
    margin: -5px 0 0 -1px;
  }
  &.jcf-disabled {
    background: none !important;
    opacity: 0.3;
  }
}