#errors {
    position: relative;
    z-index: 5000;
}
.alert {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    @include border-radius-none;
    ul {
        -webkit-padding-start: 20px;
    }
}