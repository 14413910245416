$content-width: 1240;

nav.navbar.navbar-default {
    .navbar-collapse.collapse {
        padding-right: 0;
    }
    a.navbar-brand {
        span {
            font-size: 2.5rem;
            line-height: 2em;
        }
    }
}

body{
    &.hp,
    &.resta,
    &.contact,
    &.page,
    &.text-page{
        //overflow-x: hidden;
        h1, h2, h3, h4, p{
            position: relative;
            width: 100%;
            float: left;
            text-align: left;
            margin-bottom: 0;
        }
        h1, h2, h3{
            font-family: $font-open-sans;
            font-weight: 300;
        }
        h1{
            font-size: 4rem;
        }
        h2{
            font-size: 4rem;
            line-height: 1.5;
            letter-spacing: .022em;
            small{
                font-size: 35%;
                color: #8d8d8d;
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: .15em;
            }
        }
        h3{
            font-size: 4.4rem;
            line-height: 1.22;
            letter-spacing: .022em;
            small{
                font-size: 35%;
                color: #8d8d8d;
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: .15em;
            }
        }
        p{
            font-family: $font-open-sans;
            font-weight: normal;
        }
        .top{
            height: 70rem;
            height: 100vh;
            min-height: 30rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
body.hp{
    background: #fcf9fa;
    h1{
        font-size: 6rem;
        margin-top: 29rem;
        line-height: 1.15;
        small{
            font-size: 37%;
            margin-top: 2.5rem;
            display: block;
        }
    }
    p{
        font-size: 1.9rem;
        color: $dark-gray;
    }
    .top{
        overflow: visible;
        height: auto;
        background: #ffe9e1; /* Old browsers */
        background: -moz-linear-gradient(top, #ffe9e1 0%, #fcf9fa 60%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #ffe9e1 0%,#fcf9fa 60%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #ffe9e1 0%,#fcf9fa 60%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffe9e1', endColorstr='#fcf9fa',GradientType=0 ); /* IE6-9 */
        .m-button-wrapper{
            margin-top: 8rem;
        }
        h3{
            text-align: center;
            margin-top: 39rem;
        }
    }
    .part1, .part2, .part3, .part4, .part5{
        h3{
            margin-top: 11rem;
        }
        p{
            margin-top: 2rem;
        }
        .m-images-wrapper{
            margin-top: 3rem;
        }
    }
    .part1{
        h2{
            text-align: center;
        }
        .a-leaf{
            &.leaf1{
                width: 11.85rem;
                top: -12%;
                left: -2.5%;
            }
            &.leaf2{
                width: 13.9rem;
                right: -4%;
                top: 40%;
            }
        }
        .o-tiles .m-tile p{
            margin-top: 1.5rem;
        }
        .restaurant-picto{
            width: 7.9rem;
        }
        .dinner-picto{
            width: 8.8rem;
        }
        .pc-picto{
            width: 9.3rem;
        }
    }
    .part2, .part3{
        .m-button-wrapper{
            margin-top: 4rem;
        }
    }
    .part2{
        .m-images-wrapper{
            img{
                margin-right: 15%;
                &:last-child{
                    margin-right: 0;
                }
            }
            .device-desktop{
                width: 89px;
            }
            .device-mobile{
                width: 38px;
            }
            .device-tablet{
                width: 57px;
            }
        }
        .a-leaf{
            &.leaf1{
                width: 15.4em;
                top: -7.9em;
                left: 55.4em;
            }
            &.leaf2{
                width: 15.2em;
                top: 72.3em;
                left: 17.6em;
            }
        }
    }
    .part3{
        h3{
            margin-top: 27rem;
        }
        .m-images-wrapper{
            img{
                height: 78px;
            }
        }
        .a-leaf{
            &.leaf1{
                width: 15.4em;
                top: 82.1em;
                right: 65.2em;
            }
            &.leaf2{
                width: 15.2em;
                top: 8.7em;
                right: 104.4em;
            }
        }
    }
    .part4{
        z-index: -2;
        background: #fdf3f2;
        h3{
            margin-top: 23rem;
        }
        .m-images-wrapper{
            img{
                height: 78px;
            }
        }
    }
    .part5{
        padding-bottom: 31rem;
        h3{
            margin-top: 26rem;
        }
        .m-button-wrapper{
            margin-top: .5rem;
        }
        .a-leaf{
            &.leaf1{
                width: 12.7em;
                right: 54.3em;
                top: 4.5em;
            }
            &.leaf2{
                width: 16.9em;
                right: -1em;
                top: 69.7em;
            }
        }
    }
    .part6{
        color: $white;
        padding: 0;
        h3, p, .m-button-wrapper{
            text-align: center;
        }
        .l-bg{
            background: url(../imgs/hp/rest-bg.jpg) no-repeat;
            background-size: cover;
            padding-bottom: 25rem;
        }
        h3{
            margin-top: 15rem;
        }
        p{
            color: $white;
            margin-top: 2rem;
            padding: 0 25%;
            font-weight: 300;
        }
        .m-button-wrapper{
            margin-top: 3rem;
        }
    }
    .part7{
        padding-bottom: 21rem;
    }
    .part8{
        background: #535353;
        padding: 16rem 0 14rem;
        p{
            font-size: 4.5rem;
            color: $white;
            text-align: center;
            font-weight: 300;
            padding: 0 12%;
            letter-spacing: .04em;
        }
        .m-button-wrapper{
            margin-top: 3rem;
            text-align: center;
        }
    }
}
body.resta{
    background: $white;
    h1, h2{
        text-align: center;
    }
    h1{
        margin-top: 15rem;
        margin-top: 7.8vw;
        letter-spacing: .02em;
        color: $white;
        small{
            color: $white;
            font-size: 50%;
        }
    }
    header{
        nav.navbar.navbar-default{
            a.navbar-brand, .navbar-nav > li > a{
                color: $white;
                border-color: $white;
            }
        }
    }
    .o-tiles .m-tile{
        padding: 0 10%;
    }
    .top, .part2{
        color: $white;
    }
    .top{
        background: url(../imgs/rest-bg.jpg) no-repeat;
        background-size: cover;
        .m-button-wrapper{
            margin-top: 3rem;
        }
    }
    .part1{
        color: $black;
        h2{
            margin-top: 0;
        }
        .o-tiles{
            margin-top: 8rem;
            .m-tile{
                img{
                    &.team-picto, &.graph-picto{
                        width: 109px;
                    }
                    &.clock-picto{
                        width: 108px;
                    }
                    &.worker-picto{
                        width: 102px;
                    }
                }
                h4{
                    font-size: 2.1rem;
                    line-height: 1.4;
                }
            }
        }
    }
    .part2{
        h2{
            margin-top: 0;
        }
        background: url(../imgs/proces-bg.jpg) no-repeat;
        background-size: cover;
        .o-content-switch{
            .m-content-switch img{
                width: 109px;
            }
        }
        .o-tiles .m-tile{
            height: 15rem;
            &, & *{
                text-align: left;
            }
            h4{
                font-size: 3rem;
                margin-top: 0;
                font-weight: 300;
            }
            p{
                color: $white;
            }
            h4, p{
                transform: translate3d(0,0,0);
            }
        }
    }
    .part3{
        .bg-left{
            background: url(../imgs/resta/bg-picto-left.png) no-repeat top right;
            background-size: 181px auto;
            min-height: 300px;
            margin-top: 14rem;
        }
        .bg-right{
            background: url(../imgs/resta/bg-picto-right.png) no-repeat top left;
            background-size: 205px auto;
            min-height: 300px;
            margin-top: 16rem;
        }
        .content{
            padding: 0 8%;
        }
        h2, p{
            text-align: center;
        }
        P{
            margin-top: 1.5rem;
            line-height: 1.6;
            color: $dark-gray;
        }
        h3 {
            &.text-center {
                text-align: center;
            }
        }
        form{
            margin-top: 2rem;
            display: block;
            float: left;
            position: relative;
            width: 100%;
            input[type=text], input[type=email], input[type=tel], input[type=submit], textarea{
                width: 100%;
                box-sizing: border-box;
                padding: 0 2rem;
            }
            input[type=text], .jcf-select, input[type=email], input[type=tel], input[type=submit], textarea{
                border-radius: 3px;
                height: 5rem;
                margin-top: 2rem;
                font-size: 1.6rem;
            }
            textarea {
                height: 15rem;
                padding-top: 10px;
                padding-bottom: 10px;
            }
            input[type=text], input[type=email], input[type=tel], textarea{
                border: 1px solid #ccc;
                -webkit-appearance: none;
            }
            input[type=submit]{
                background: #ff5747; /* Old browsers */
                background: -moz-linear-gradient(left, #ff5747 0%, #ff4757 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left, #ff5747 0%,#ff4757 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right, #ff5747 0%,#ff4757 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5747', endColorstr='#ff4757',GradientType=1 ); /* IE6-9 */
                border: none;
                color: $white;
                text-transform: uppercase;
            }
            .jcf-select{
                border-top: 1px solid #cccccc;
                border-left: 1px solid #cccccc;
                border-right: 1px solid #cccccc;
                padding: 1.2rem;
                .jcf-select-opener{
                    width: 6rem;
                }
            }
        }
    }
}
body {
    &.contact,
    &.text-page{
        background: #fcf9fa;
    }
    &.page {
        background: #fff;
    }
    &.contact,
    &.page,
    &.text-page{
         header{
             nav.navbar.navbar-default{
                 a.navbar-brand,
                 .navbar-nav > li > a{
                     color: #777;
                     &:hover,
                     &:focus {

                     }
                     &.btn {
                         color: #ff4747;
                         border-color: #ff4747;
                     }

                 }
                 .navbar-toggle .icon-bar {
                     background: #ff4856;
                 }
             }
         }
         .top {
             background: $white;
             display: block;
             color: #777;
             font-size: 2rem;
             height: auto;
             min-height: 50rem;
             padding-top: 20rem;
             border-bottom: 1px solid #f1f1f1;
             .col-md-6.land {
                 padding: 45px 30px;
                 &.land-delay-2 {
                     border-right: 1px solid #f1f1f1 !important;
                 }
             }
             p.text-right {
                 text-align: right;
             }
             @media (max-width: $screen-sm-max) {
                 p {
                     text-align: center !important;
                     border-right: 0;
                 }
             }
         }
         .part1 {
             h2 {
                 margin-bottom: 3.5rem;
             }
             h3 {
                 text-align: center;
             }
             #map {
                 height: 450px;
             }
         }
         .part3 {
             background: $white;
             border-top: 1px solid #f1f1f1;
         }
         .part6{
             color: $white;
             padding: 0;
             h3, p, .m-button-wrapper{
                 text-align: center;
             }
             .l-bg{
                 background: url(../imgs/hp/rest-bg.jpg) no-repeat;
                 background-size: cover;
                 padding-bottom: 25rem;
             }
             h3{
                 margin-top: 15rem;
             }
             p{
                 color: $white;
                 margin-top: 2rem;
                 padding: 0 25%;
                 font-weight: 300;
             }
             .m-button-wrapper{
                 margin-top: 3rem;
             }
         }
         .part7{
             .o-tiles-slider-wrapper{
                 height: 27rem;
                 .m-tiles-slider-controlls .m-controls-wrapper{
                     margin-top: 29rem;
                 }
                 .o-tiles-slider .m-tile{
                     .a-image{
                         height: 10rem;
                         width: 10rem;
                     }
                     .m-top{
                         font-size: 2.3rem;
                     }
                     p{
                         font-size: 1.5rem;
                     }
                 }
             }
         }
     }
}
body.contact {
    .part3{
        .bg-left{
            background: url(../imgs/resta/bg-picto-left.png) no-repeat top right;
            background-size: 181px auto;
            min-height: 300px;
            margin-top: 14rem;
        }
        .bg-right{
            background: url(../imgs/resta/bg-picto-right.png) no-repeat top left;
            background-size: 205px auto;
            min-height: 300px;
            margin-top: 16rem;
        }
        .content{
            padding: 0 8%;
        }
        h2, p{
            text-align: center;
        }
        P{
            margin-top: 1.5rem;
            line-height: 1.6;
            color: $dark-gray;
        }
        form{
            margin-top: 5rem;
            display: block;
            float: left;
            position: relative;
            width: 100%;
            input[type=text], input[type=email], input[type=tel], input[type=submit], textarea{
                width: 100%;
                box-sizing: border-box;
                padding: 0 2rem;
            }
            input[type=text], .jcf-select, input[type=email], input[type=tel], input[type=submit], textarea{
                border-radius: 3px;
                height: 5rem;
                margin-top: 2rem;
                font-size: 1.6rem;
            }
            textarea {
                height: 15rem;
                padding-top: 10px;
                padding-bottom: 10px;
            }
            input[type=text], input[type=email], input[type=tel], textarea{
                border: 1px solid #ccc;
                -webkit-appearance: none;
            }
            input[type=submit]{
                background: #ff5747; /* Old browsers */
                background: -moz-linear-gradient(left, #ff5747 0%, #ff4757 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left, #ff5747 0%,#ff4757 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right, #ff5747 0%,#ff4757 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5747', endColorstr='#ff4757',GradientType=1 ); /* IE6-9 */
                border: none;
                color: $white;
                text-transform: uppercase;
            }
            .jcf-select{
                border-top: 1px solid #cccccc;
                border-left: 1px solid #cccccc;
                border-right: 1px solid #cccccc;
                padding: 1.2rem;
                .jcf-select-opener{
                    width: 6rem;
                }
            }
        }
    }
    .part6{
        h3{
            margin-top: 4rem;
        }
        p{
            font-size: 1.5rem;
        }
        .l-bg{
            padding-bottom: 15rem;
        }
    }
}
body.page {
    header {
        nav.navbar.navbar-default {
            a.navbar-brand, .navbar-nav > li > a {
                color: $white;
                border-color: $white;
                &.btn {
                    color: $white;
                    border-color: $white;
                }
            }
            .navbar-toggle .icon-bar {
                background: $white;
            }
        }
    }
    .top {
        h1 {
            text-align: center;
            &,
            small {
                color: $white;
            }
            small {
                font-size: 2rem;
                display: block;
            }
            img {
                width: 70px;
            }
        }
        * {
            margin-bottom: 20px;
        }
        .carousel-control {
            margin-bottom: 0;
        }
    }
    .part {
        p {
            margin-bottom: 15px;
        }
    }
    @media screen and (max-width: $screen-xs-max) {
        .quick-order-bottom {
            padding-bottom: 400px;
            img {
                width: 80%;
            }
        }
    }
}
body.text-page {
    .top {
        background: #fcf9fa;
        border-bottom: 0;
        h1 {
            text-align: center;
            small {
                font-size: 2rem;
                display: block;
            }
            img {
                width: 70px;
            }
        }
        * {
            margin-bottom: 20px;
        }
        .carousel-control {
            margin-bottom: 0;
        }
    }
}
@media (max-width: $screen-md-max) {
    ul.relax li, ul#parallax_scene li{
        font-size: .65rem;
    }
    body.hp,
    body.resta,
    body.contact {
        h3{
            font-size: 3.5rem;
            &.a-bottom-line + p{
                font-size: 1.4rem;
            }
        }
    }
    body.hp{
        h1{
            font-size: 4.5rem;
            line-height: 1.5;
            margin-top: 20rem;
        }
        ul#parallax_scene, .top img.l-2nd-bg{
            transform: translate3d(-12rem, 5rem, 0px) !important;
            transform-origin: top;
        }
        .top{
            .m-button-wrapper{
                margin-top: 5rem;
            }
            h2{
                margin-top: 25rem;
            }
            img.l-2nd-bg{
                width: 105em;
                right: -42.5em;
                top: -22em;
            }
        }
        .part1{
            .o-tiles .m-tile{
                height: 31rem;
                h4{
                    font-size: 2.5rem;
                }
                p{
                    font-size: 1.5rem;
                    margin-top: .6rem;
                }
            }
        }
        .part2{
            h3{
                margin-top: 0;
            }
        }
        .part3{
            h3.a-bottom-line{
                margin-top: 7rem;
            }
            ul.relax{
                margin-top: -5rem;
            }
        }
        .part4{
            h3.a-bottom-line{
                margin-top: 13rem;
            }
        }
        .part5{
            padding-bottom: 20rem;
            h3.a-bottom-line{
                margin-top: 9rem;
            }
            ul.relax{
                margin-top: -4rem;
            }
        }
        .part6{
            h3{
                margin-top: 4rem;
            }
            p{
                font-size: 1.5rem;
            }
            .l-bg{
                padding-bottom: 15rem;
            }
        }
        .part7{
            padding-bottom: 7rem;
            .o-tiles-slider-wrapper{
                height: 27rem;
                .m-tiles-slider-controlls .m-controls-wrapper{
                    margin-top: 29rem;
                }
                .o-tiles-slider .m-tile{
                    .a-image{
                        height: 10rem;
                        width: 10rem;
                    }
                    .m-top{
                        font-size: 2.3rem;
                    }
                    p{
                        font-size: 1.5rem;
                    }
                }
            }
        }
        .part8{
            padding: 9rem 0;
            p{
                font-size: 3.2rem;
            }
        }
    }
    body.resta{
        h1{
            margin-top: 5rem;
        }
        .part2{
            h2{
                margin-top: 5rem;
            }
            padding: 0 10%;
            .o-tiles .m-tile{
                height: 30rem;
                h4{
                    font-size: 2.5rem;
                }
                p{
                    font-size: 1.6rem;
                    opacity: .8;
                }
            }
        }
        .part3{
            .content{
                padding: 0 4%;
            }
        }
    }
}
@media (max-width: $screen-sm-max) {
    ul.relax li{
        font-size: .6rem;
    }
    ul#parallax_scene li{
        font-size: .5rem;
    }
    body.hp{
        ul#parallax_scene, .top img.l-2nd-bg{
            transform: translate3d(-8rem, 0px, 0px) !important;
        }
        h1{
            font-size: 3.5rem;
            margin-top: 20%;
        }
        .top{
            h2{
                margin-top: 5rem;
            }
            img.l-2nd-bg{
                width: 80em;
                right: -32.5em;
                top: -16em;
            }
        }
        .part1{
            .a-leaf{
                display: none !important;
            }
            h2{
                font-size: 3.2rem;
            }
            .o-tiles.type-1{
                margin-top: 0;
                .m-tile{
                    height: auto;
                    padding-bottom: 4rem;
                    margin-top: 4rem;
                }
            }
        }
        .part5{
            padding-bottom: 6rem;
            a.a-btn-app{
                clear: left;
                height: 7rem;
                padding-top: 1.7rem;
                padding-bottom: 1.5rem;
                &.android{
                    margin-left: 0;
                }
            }
        }
        .part6{
            .l-bg{
                padding: 4rem 0 8rem;
            }
        }
    }
    main.resta{
        h2{
            font-size: 4rem;
        }
    }
}
@media (max-width: $screen-sm-max) and (orientation: landscape){
    body.resta{
        h1{
            font-size: 3rem;
            padding: 0 15%;
            margin-top: 0;
        }
    }
}
@media (max-width: $screen-xs-max) {
    ul.relax li, ul#parallax_scene li{
        font-size: .42rem;
    }
    body.hp{
        ul#parallax_scene, .top img.l-2nd-bg{
            transform: translate3d(0px, 0px, 0px) !important;
        }
        h1{
            font-size: 3.5rem;
            margin-top: 110%;
            text-align: center;
            small{
                font-size: 42%;
            }
        }
        h2.a-bottom-line + p{
            font-size: 1.5rem;
        }
        .top{
            padding-top: 10rem;
            .layer1 img{
                width: 90%;
                top: -9%;
                right: 0;
                left: 0;
            }
            .layer2 img{
                width: 80%;
                top: 4%;
                right: 0;
                left: 0;
            }
            .layer3 img {
                width: 65%;
                top: 6%;
                right: 0;
                left: 45%;
            }
            .layer4 img {
                width: 50%;
                top: 3%;
                right: 62%;
                left: -20%;
            }
        }
        .top, .part2{
            .m-button-wrapper{
                text-align: center;
            }
        }
        .part2{
            ul.relax{
                height: 36rem;
                margin-top: 7rem;
            }
        }
        .part3{
            h3.a-bottom-line{
                margin-top: 0;
            }
            ul.relax{
                margin-top: 0;
                height: 30rem;
            }
        }
        .part4{
            h3.a-bottom-line{
                margin-top: 9rem;
            }
            .m-images-wrapper img{
                max-height: 78px;
                max-width: 100%;
            }
            ul.relax{
                height: 25rem;
            }
        }
        .part6{
            p{
                padding: 0 12%;
            }
        }
        .part8{
            font-size: 2.5rem;
        }
    }
    body.resta, body.hp{
        h2, h3{
            font-size: 3rem;
            small{
                font-size: 45%;
            }
        }
    }
    body.resta{
        h1{
            font-size: 3rem;
            margin-top: 0;
            line-height: 1.15;
        }
        .part2{
            padding-bottom: 15rem;
            h2{
                margin-bottom: 4rem;
            }
        }
    }
}

@media (max-width: $screen-xs-max){
    body.hp .top img.l-2nd-bg{
        left: -100%;
        right: -100%;
        top: -20%;
        margin: auto;
        width: 200%;
    }
}