tags{
    $tagMargin        : 5px;
    $tagPad           : .3em .5em;
    $tag-text-color   : black;
    $tag-bg           : #E5E5E5;
    $tag-hover        : #D3E2E2;
    $tag-remove       : #D39494;
    $showX            : true;

    display: block;
    border: none;
    margin-top: 40px;

    @media (min-width: $screen-sm-min) {
        margin-top: 0;
        border: 1px solid #e6e6e6;
        @include rounded(17px);
    }

    @media (min-width: $screen-md-min) {
        background-color: $white;
    }

    &:hover{ border-color:#CCC; }

    @keyframes tags--bump{
        30% { box-shadow:0 0 0 4px $tag-bg; }
    }

    tag{
        display        : inline-block;
        margin         : $tagMargin 0 $tagMargin $tagMargin;
        vertical-align : top;
        background: #000;
        color: #fff;
        @include rounded(17px);
        position       : relative;
        cursor         : default;
        transition     : .13s ease-out;
        animation      : .3s tags--bump 1 ease-out;
        @media (min-width: $screen-sm-min) {
            background: #fff;
            border: 1px solid #e6e6e6;
            color: #343434;
            margin-top: 4px;
            margin-bottom: 0;
        }

        > div{
            color: #fff;
            padding: 2px 1.5em 2px 15px;
            vertical-align : top;
            position       : relative;
            box-sizing     : border-box;
            max-width      : 100%;
            transition     : .13s ease-out;
            @media (min-width: $screen-sm-min) {
                color: #343434;
                padding-top: 1px;
                padding-bottom: 1px;
            }

            > span{
                white-space    : nowrap;
                overflow       : hidden;
                text-overflow  : ellipsis;
                display        : inline-block;
                vertical-align : top;
                width          : 100%;
                transition     : .1s;
            }

            @if $showX {
                padding-right:nth($tagPad, 2) * 3;
            }

            &::before{
                content: '';
                position: absolute;
                left:0; top:0; right:0; bottom:0;
                background: $tag-bg;
                border-radius : 3px;
                z-index: -1;
                pointer-events:none;
                transition:80ms ease;
            }
        }

        &:hover{
            div{
                &::before{
                    $size: -$tagMargin/2;
                    $size: -2px;
                    top:$size; right:$size; bottom:$size; left:$size;
                    background: $tag-hover;
                    box-shadow: 0 0 0 0 $tag-remove inset
                }
                // background:nth($tagColor,2);
                //background:none;
                // box-shadow: 0 0 0 2px $tag-hover inset;
                // transition:50ms;
            }
        }

        &.tagify{
            &--noAnim{ animation:none; }

            &--hide{
                width          : 0 !important;
                padding-left   : 0;
                padding-right  : 0;
                margin-left    : 0;
                margin-right   : 0;
                opacity        : 0;
                transform      : scale(0);
                transition     : .3s;
                pointer-events : none;
            }

            &--mark{
                @keyframes tagify--pulse{
                    25%{ background: rgba($tag-remove, .6); }
                }
                div::before{ animation:.3s tagify--pulse 2 ease-out;  }
            }

            &--notAllowed{
                div{
                    > span{ opacity:.5; } // filter:blur(.2px);
                    &::before{ background:rgba($tag-remove, .44); transition:.2s; }
                }
            }
        }

        ///////////////////////////////////////////
        // Close button
        x{
            $size: 14px;

            font          : #{$size}/#{$size} Serif;
            width         : $size;
            height        : $size;
            text-align    : center;
            border-radius : 50px;
            position      : absolute;
            z-index       : 1;
            right         : calc(#{nth($tagPad, 2)} - 2px);
            top           : 50%;
            cursor        : pointer;
            transform     : translateY(-50%);
            transition    : .2s ease-out;

            &::after{
                content:"\00D7";
            }

            &:hover{
                color:white;
                background:darken($tag-remove, 8);
                // + span{ box-shadow: 0 0 0 2px $tag-remove inset; transition:.2s; }
                + div{
                    > span{ opacity:.5; } // filter:blur(.2px);
                    &::before{ background:rgba($tag-remove, .44); transition:.2s; }
                }
            }
        }
    }

    ///////////////////////////////////////////
    // Hides originals
    input, textarea{ border:0; display:none; }

    // remove datalist doprdown annoying arrow
    // http://stackoverflow.com/q/20937475/104380
    input::-webkit-calendar-picker-indicator {
        display: none;
    }

    ///////////////////////////////////////////
    // Holds the placeholder & the tags input
    > div{
        display: block;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        @media (min-width: $screen-sm-min) {
            display: inline-block;
            position: relative;
            width: auto;
        }
        > input{
            display:block;
            min-width:130px;
            @media (min-width: $screen-sm-min) {
                border: none !important;
            }
            &:focus{
                outline:none;
            }
            &.placeholder{
                ~ span{
                    opacity:1;
                    transform:none;
                    font-family: $font-source;
                    font-size: 14px;
                    font-weight: 300;
                    color: #343434;
                }
            }
        }
        > span{
            display: block;
            padding: 7px 17px 7px 53px;
            opacity: 0;
            line-height: 1.8;
            position:absolute;
            top: 0;
            z-index:1;
            white-space:nowrap;
            pointer-events:none;
            transform:translatex(6px);
            transition:.15s ease-out;
            @media (min-width: $screen-sm-min) {
                padding: 3px 17px;
            }
        }
    }
}