.l-full-width{
    position: relative;
    float: left;
    clear: both;
    width: 100%;
    height: auto;
    overflow: visible;
    padding: 10rem 4rem 13rem;
    padding: 5.2vw 4rem 6.75vw;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    &.part7 {
        overflow-x: hidden;
        overflow-y: auto;
    }
}
.l-full-width-inner{
    width: 1240px;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    overflow: visible;
    position: relative;
}
.l-bg{
    height: auto;
    position: relative;
    float: left;
    width: calc(100% - 52px);
    border-radius: 5px;
    padding: 10rem 0 13rem
}
.l-2nd-bg{
    position: absolute;
    z-index: -2;
}
footer{
    position: relative;
    float: left;
    clear: both;
    width: 100%;
    height: auto;
    padding: 5.3rem 5rem 3.8rem;
    color: $white;
    background: $footer-bg;
    a{
        transition: .2s;
        color: $white;
        &:hover{
            color: $white;
            opacity: .7;
        }
    }
    nav{
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            li{
                padding: 0 1rem;
                margin: 0;
                float: left;
                a{
                    color: $white;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-size: 1.75rem;
                    letter-spacing: .03em;
                    line-height: 2;
                    font-family: $font-open-sans;
                    font-weight: 600;
                }
            }
        }
    }
}
#errors {
    z-index: 9999;
}
nav.navbar.navbar-default{
    background: transparent;
    border: none;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 6rem 6rem 0;
    width: 100%;
    z-index: 9998;
    .navbar-toggle{
        border: none;
        margin-right: 5rem;
        z-index: 9999;
        .icon-bar{
            background: #ff4856;
            width: 30px;
            &+ .icon-bar{
                margin-top: 7px;
            }
        }
        &:hover, &:focus{
            background: transparent;
            border: none;
        }
    }
    a.navbar-brand{
        padding: 0;
        width: auto;
        font-weight: 400;
        line-height: 2.5rem;
        font-size: 2.5rem;
        img{
            float: left;
            margin-right: .9rem;
            width: 8rem;
            height: auto;
        }
    }
    ul.navbar-nav{
        float: right;
        width: auto;
        li{
            a{
                text-transform: uppercase;
                height: 5.5rem;
                line-height: 5.4rem;
                padding: .1rem 2.3rem 0;
                font-size: 1.4rem;
                font-family: $font-source;
                transition: .2s;
                border: none;
                letter-spacing: .03em;
            }
            a.btn-border{
                border: 1px solid #ff4856;
                color: #ff4856;
                padding: 0 4.5rem;
                border-radius: 2.75rem;
                background: transparent;
                margin-left: 2rem;
                width: auto;
            }
        }
    }
}
body.resta nav.navbar.navbar-default .navbar-toggle .icon-bar{
    background: $white;
}

@media (max-width: $screen-md-max) {
    .l-full-width{
        padding-top: 7rem;
        padding-bottom: 7rem;
    }
    footer{
        height: auto;
        padding: 4rem 0 3rem;
    }
}
@media (max-width: $screen-sm-max) {
    body{
        header nav.navbar.navbar-default{
            padding: 3rem 0 0;
            a.navbar-brand{
                margin-left: 5rem;
                padding-top: 1.5rem;
                &> img{
                    width: 6rem;
                }
            }
            .navbar-collapse{
                background: $white;
                border: none;
                padding-left: 0;
                margin-top: 2rem;
            }
            ul.navbar-nav{
                width: 100%;
                margin: 0;
                padding-bottom: 2rem;
                & > li > a{
                    color: $light-gray !important;
                    border: none;
                    text-align: center;
                    margin: 0;
                }
            }
        }
    }
}
@media (max-width: $screen-xs-max) {
    .l-full-width{
        padding: 3rem 1.5rem;
    }
    .l-bg{
        width: 100%;
        border-radius: 0px;
        padding: 6rem 0;
    }
}