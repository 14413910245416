/* checkbox */
.jcf-checkbox {
  vertical-align: middle;
  display: block;
  position: relative;
  overflow: hidden;
  float: left;
  cursor: default;
  background: #fff;
  border: 1px solid #e7e7e7;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  &.jcf-checked {
    border: 1px solid #ec575b;
    background: #ec575b url("../imgs/icons/checkbox.png") no-repeat;
    background-size: 11px 9px;
    background-position: 4px;
  }
  input[type="checkbox"] {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0;
    margin: 0;
    left: 0;
    top: 0;
  }
}