.star-rating{
    display: block;
    span,
    label {
        display: inline-block;
        margin-right: 8px;
        width: 22px;
        height: 22px;
        background-image: url('../imgs/icons/star.png');
        background-size: 22px 44px;
        &.active {
            background-position: 0 22px;
        }
        &.user-rated{
            font-family: $font-source;
            font-weight: 300;
            font-size: 14px;
            color: #666666;
            display: block;
            width: auto;
            height: auto;
            background: none;
            padding-top: 10px;
        }
    }
    label {
        float: right;
    }
    &.choose {
        .rating-star:hover,
        .rating-star:hover ~ .rating-star,
        .rating-input:checked ~ .rating-star {
            background-position: 0 22px;
        }
    }
}