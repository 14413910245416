/* radio */
.jcf-radio {
  vertical-align: middle;
  display: inline-block;
  position: relative;
  overflow: hidden;
  cursor: default;
  background: #fff;
  border: 1px solid #777;
  border-radius: 9px;
  margin: 0 3px 0 0;
  height: 16px;
  width: 16px;
  span {
    display:none;
    position:absolute;
    top:3px;
    left:3px;
    right:3px;
    bottom:3px;
    background:#777;
    border-radius:100%;
  }
  input[type="radio"] {
    position: absolute;
    height: 100%;
    width: 100%;
    border: 0;
    margin: 0;
    left: 0;
    top: 0;
  }
  &.jcf-checked span {
    display:block;
  }
}