@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Comfortaa:300&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600&subset=latin,latin-ext');

/*
 * Web Fonts from fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at http://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and 
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2016 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      exljbris Font Foundry
 * License URL: https://www.fontspring.com/licenses/exljbris/webfont
 *
 *
 */

@font-face {
    font-family: 'museo_sans500';
    src: url('../fonts/museo-sans/mobile/MuseoSans_500-webfont.eot');
    src: url('../fonts/museo-sans/mobile/MuseoSans_500-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/museo-sans/mobile/MuseoSans_500-webfont.woff2') format('woff2'),
         url('../fonts/museo-sans/mobile/MuseoSans_500-webfont.woff') format('woff'),
         url('../fonts/museo-sans/mobile/MuseoSans_500-webfont.ttf') format('truetype'),
         url('../fonts/museo-sans/mobile/MuseoSans_500-webfont.svg#museo_sans500') format('svg');
    font-weight: normal;
    font-style: normal;

}

//Fonts variables
$font-museo: "museo_sans500", "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
$font-open-sans: "Open sans", "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
$font-source: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
$font-comfortaa: "Comfortaa", cursive !important;
