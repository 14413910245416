.rotate-90 {
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

.rotate-180 {
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.rotate-270 {
    -moz-transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
}

.flip {
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}

.flip-and-rotate-90 {
    -moz-transform: rotate(90deg) scaleX(-1);
    -webkit-transform: rotate(90deg) scaleX(-1);
    -o-transform: rotate(90deg) scaleX(-1);
    transform: rotate(90deg) scaleX(-1);
}

.flip-and-rotate-180 {
    -moz-transform: rotate(180deg) scaleX(-1);
    -webkit-transform: rotate(180deg) scaleX(-1);
    -o-transform: rotate(180deg) scaleX(-1);
    transform: rotate(180deg) scaleX(-1);
}

.flip-and-rotate-270 {
    -moz-transform: rotate(270deg) scaleX(-1);
    -webkit-transform: rotate(270deg) scaleX(-1);
    -o-transform: rotate(270deg) scaleX(-1);
    transform: rotate(270deg) scaleX(-1);
}