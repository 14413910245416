@import 'variables/typography';
@import 'variables/variables';

// 0 - 639 = xs
// 640 - 1024 = sm
// 1025 - 1240 = md
// 1240 - xxx = lg

/**
GLOBAL
 */
html {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    //overflow-x: hidden;
}
body{
    background-color: #f5f8fa;
    //padding-bottom: 75px;
    //overflow-x: hidden;
    //&.hp {
    //    .top,
    //    .part7 {
    //        overflow-x: hidden;
    //    }
    //}
    &.food {
        padding: 211px 0 75px 0;
        @include transition;
        &.sticky, &.landscape{
            padding-top: 100px;
        }
        &.white-bg{
            background-color: $white;
        }
    }
    @media (min-width: $screen-md-min) {
        &.landscape{
            .food-detail{
                margin-top: 0;
            }
        }
    }
}

hr {
    border-color: #f1f1f1;
}

strong.red {
    color: #ff4c48;
}

.margin-auto {
    margin: auto;
}

.absolute {
    position: absolute;
}
.bottom {
    bottom: 0;
}
.right {
    right: 0;
}
.z-0 {
    z-index: 0;
}
.z-1 {
    z-index: 1;
}
.w-50 {
    width: 50%;
}

.relative {
    position: relative;
}

.font-open-sans {
    font-family: $font-open-sans;
}

.container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.container-fluid > .col-xs-12 {
    padding-left: 0;
    padding-right: 0;
}

.list-inline > li {
    padding-right: 0;
    padding-left: 0;
}

.row {
    margin-left: 0;
    margin-right: 0;
}

.top-navigation__logo {
    font-family: "Comfortaa", cursive;
}

//@import "header/top";
@import "content/login/login";

@media (max-width: $screen-sm-max) {
    .login {
        .content {
            min-height: 600px;
        }
        header,
        footer {
            display: none;
        }
    }
    .register {
        .content {
            min-height: 900px;
        }
    }
}
@media (min-width: $screen-md-min) {
    .login {
        .content {
            min-height: 700px;
        }
    }
}

@import "components/atoms";
@import "components/molecules";
@import "components/organisms";
@import "components/layout";
@import "components/pages";
@import "components/landings";

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 120dpi){
    html{
        font-size: 8px;
    }
}

.home-overflow {
    overflow: hidden;
    z-index: 10;
    & > div {
        z-index: 11;
    }
}
#contact-form,
#restaurant-contact-form {
    z-index: 11;
}

iframe[name="google_conversion_frame"]{
    position: absolute;
    display: none;
}

#cd-timeline {
    position: relative;
    padding: 2em 0;
    margin-top: 2em;
    margin-bottom: 2em;
}
@media only screen and (min-width: 1170px) {
    #cd-timeline {
        margin-top: 3em;
        margin-bottom: 3em;
    }
    #cd-timeline::before {
        left: 50%;
        margin-left: -2px;
    }
}

.cd-timeline-block {
    position: relative;
    margin: 2em 0;
}
.cd-timeline-block:after {
    content: "";
    display: table;
    clear: both;
}
.cd-timeline-block:first-child {
    margin-top: 0;
}
.cd-timeline-block:last-child {
    margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
    .cd-timeline-block {
        margin: 4em 0;
    }
    .cd-timeline-block:first-child {
        margin-top: 0;
    }
    .cd-timeline-block:last-child {
        margin-bottom: 0;
    }
}

.cd-timeline-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    //border-radius: 50%;
    //box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.cd-timeline-img img {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    left: 20%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px;
}
@media only screen and (min-width: 1170px) {
    .cd-timeline-img {
        width: 60px;
        height: 60px;
        left: 50%;
        margin-left: -30px;
        /* Force Hardware Acceleration in WebKit */
        -webkit-transform: translateZ(0);
        -webkit-backface-visibility: hidden;
    }
    .cssanimations .cd-timeline-img.is-hidden {
        visibility: hidden;
    }
    .cssanimations .cd-timeline-img.bounce-in {
        visibility: visible;
        -webkit-animation: cd-bounce-1 0.6s;
        -moz-animation: cd-bounce-1 0.6s;
        animation: cd-bounce-1 0.6s;
    }
}

@-webkit-keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
    }
}
@-moz-keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -moz-transform: scale(0.5);
    }

    60% {
        opacity: 1;
        -moz-transform: scale(1.2);
    }

    100% {
        -moz-transform: scale(1);
    }
}
@keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
        -moz-transform: scale(0.5);
        -ms-transform: scale(0.5);
        -o-transform: scale(0.5);
        transform: scale(0.5);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}
.cd-timeline-content {
    position: relative;
    margin-left: 60px;
    background: white;
    border-radius: 0.25em;
    padding: 1em;
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.12);
}
.cd-timeline-content:after {
    content: "";
    display: table;
    clear: both;
}
.cd-timeline-content h2 {
    color: #303e49;
}
.cd-timeline-content p, .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
    font-size: 13px;
    font-size: 0.8125rem;
}
.cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
    display: inline-block;
}
.cd-timeline-content p {
    margin: 1em 0;
    line-height: 1.6;
}
.cd-timeline-content .cd-read-more {
    float: right;
    padding: .8em 1em;
    background: #acb7c0;
    color: white;
    border-radius: 0.25em;
}
.no-touch .cd-timeline-content .cd-read-more:hover {
    background-color: #bac4cb;
}
.cd-timeline-content .cd-date {
    float: left;
    padding: .8em 0;
    opacity: .7;
}
.cd-timeline-content::before {
    content: '';
    position: absolute;
    top: 10px;
    right: 100%;
    height: 0;
    width: 0;
    border: 18px solid transparent;
    border-right: 18px solid white;
}
@media only screen and (min-width: 768px) {
    .cd-timeline-content h2 {
        font-size: 20px;
        font-size: 1.25rem;
    }
    .cd-timeline-content p {
        font-size: 16px;
        font-size: 1rem;
    }
    .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
        font-size: 14px;
        font-size: 0.875rem;
    }
}
@media only screen and (min-width: 1170px) {
    .cd-timeline-content {
        margin-left: 0;
        padding: 1.6em;
        width: 45%;
    }
    .cd-timeline-content::before {
        top: 30px;
        left: 100%;
        border-color: transparent;
        border-left-color: white;
    }
    .cd-timeline-content .cd-read-more {
        float: left;
    }
    .cd-timeline-content .cd-date {
        position: absolute;
        width: 100%;
        left: 122%;
        top: 6px;
        font-size: 16px;
        font-size: 1rem;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content {
        float: right;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
        top: 24px;
        left: auto;
        right: 100%;
        border-color: transparent;
        border-right-color: white;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
        float: right;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
        left: auto;
        right: 122%;
        text-align: right;
    }
    .cssanimations .cd-timeline-content.is-hidden {
        visibility: hidden;
    }
    .cssanimations .cd-timeline-content.bounce-in {
        visibility: visible;
        -webkit-animation: cd-bounce-2 0.6s;
        -moz-animation: cd-bounce-2 0.6s;
        animation: cd-bounce-2 0.6s;
    }
}

@media only screen and (min-width: 1170px) {
    /* inverse bounce effect on even content blocks */
    .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
        -webkit-animation: cd-bounce-2-inverse 0.6s;
        -moz-animation: cd-bounce-2-inverse 0.6s;
        animation: cd-bounce-2-inverse 0.6s;
    }
}
@-webkit-keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
    }

    100% {
        -webkit-transform: translateX(0);
    }
}
@-moz-keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -moz-transform: translateX(-100px);
    }

    60% {
        opacity: 1;
        -moz-transform: translateX(20px);
    }

    100% {
        -moz-transform: translateX(0);
    }
}
@keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        -moz-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        -o-transform: translateX(-100px);
        transform: translateX(-100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}
@-webkit-keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
    }

    100% {
        -webkit-transform: translateX(0);
    }
}
@-moz-keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -moz-transform: translateX(100px);
    }

    60% {
        opacity: 1;
        -moz-transform: translateX(-20px);
    }

    100% {
        -moz-transform: translateX(0);
    }
}
@keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
        -moz-transform: translateX(100px);
        -ms-transform: translateX(100px);
        -o-transform: translateX(100px);
        transform: translateX(100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

.cookies-info{
    background:#f3f3f3;
    color:#000;
    padding:20px 10px;
    box-sizing:border-box;
    position:fixed;
    bottom:0;
    width:100%;
    z-index:1000;
    text-align:center;
    display:none;
}
.cookies-info .row{
    margin:0;
}
.cookies-info button{
    background:transparent;
    border:1px solid #c00;
    color:#c00;
    border-radius:2px;
    margin-left:20px;
    padding:5px 20px;
    font:inherit;
    cursor:pointer;
    margin-top:0;
}
.cookies-info a{
    text-decoration: underline;
}

@import 'bug-fixes';