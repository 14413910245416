@mixin transition($time: 0.3s) {
    -webkit-transition: all $time;
    -moz-transition: all $time;
    -o-transition: all $time;
    transition: all $time;
}

//@mixin box-shadow($horizontal, $vertical, $blur, $spread, $color, $inset: false) {
//	@if $inset {
//		-webkit-box-shadow:inset $horizontal $vertical $blur $spread $color;
//		-moz-box-shadow:inset $horizontal $vertical $blur $spread $color;
//		box-shadow:inset $horizontal $vertical $blur $spread $color;
//	} @else {
//		-webkit-box-shadow: $horizontal $vertical $blur $spread $color;
//		-moz-box-shadow: $horizontal $vertical $blur $spread $color;
//		box-shadow: $horizontal $vertical $blur $spread $color;
//	}
//}

@mixin box-shadow-none {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

@mixin border-radius-none {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

@mixin rounded($radius: 10px) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}


@mixin transform($transformation) {
    -webkit-transform: $transformation;
    -moz-transform: $transformation;
    -ms-transform: $transformation; // IE9 only
    transform: $transformation;
}