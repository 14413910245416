// Order button
.btn-order {
	&__1 {
		display: block;
		&__invisible {
			display: none;
		}
		&, &:link, &:visited {
			font-family: $font-source;
			font-weight: 400;
			font-size: 13px;
			text-transform: uppercase;
			text-align: center;
			border-top: 1px solid $gray-border;
			color: $white;
			background: $red-active;
			padding: 6px 0;
			&:hover, &:visited, &:active {
				text-decoration: none;
			}
		}
		&.detail {
			color: $white;
			background: #ec575b;
		}
		&__red {
			&, &:link, &:visited {
				border-top: none;
				font-size: 14px;
				color: $red-active;
				background: $white;
				@include rounded(2px);
				padding: 8px 0;
			}
		}
	}
	&__2 {
		text-align: center;
		width: calc(100% - 16px);
		padding: 3px 0 6px 0;
		margin: 0 13px 4px 13px;
		font-family: $font-source;
		font-weight: 600;
		font-size: 13px;
		color: #343434;
		background-image: url('../imgs/order-button-bg.png');
		background-repeat: repeat-x;
		&__invisible {
			display: none;
		}
		a,
		button {
			&, &:link, &:visited {
				background-color: #ec575b;
				display: inline-block;
				width: 27px;
				height: 27px;
				@include rounded(5px);
				text-align: center;
				font-family: $font-source;
				font-weight: 400;
				color: $white;
				font-size: 27px;
				line-height: 24px;
				margin-top: -3px;
			}
			&:focus, &:active, &:hover {
				text-decoration: none;
			}
		}
		&__red {
			width: 100%;
			background: none;
			border-top: 1px solid #f1f1f1;
			border-bottom: 1px solid #f1f1f1;
			@include rounded(2px);
			padding: 6px 0;
			margin: 0;
			font-size: 16px;
			.btn-order__minus {
				&, &:link, &:visited {
					width: 36px;
					height: 36px;
					line-height: 31px;
					font-size: 42px;
					@include rounded(2px);
					margin-top: -7px;
				}
			}
			.btn-order__plus {
				&, &:link, &:visited {
					width: 36px;
					height: 36px;
					line-height: 31px;
					font-size: 42px;
					@include rounded(2px);
					margin-top: -7px;
				}
			}
		}
	}
	&__minus {
		float: left;
	}
	&__plus {
		float: right;
	}

}



//Button with red border
.btn-red-border {
	display: block;
	width: 100%;
	height: 33px;
	@include transition(0.2s);
	&, &:link, &:visited {
		font-family: $font-source;
		font-weight: 400;
		font-size: 14px;
		text-transform: uppercase;
		text-align: center;
		color: #ec575b;
		padding: 7px 0 0 0;
		background: #fff;
		border: 1px solid #ff4747;
		@include rounded(17px);
	}
	&:hover, &:focus {
		color: $white;
		text-decoration: none;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff5747+0,ff4757+100 */
		background: #ff5747; /* Old browsers */
		background: -moz-linear-gradient(left,  #ff5747 0%, #ff4757 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left,  #ff5747 0%,#ff4757 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right,  #ff5747 0%,#ff4757 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5747', endColorstr='#ff4757',GradientType=1 ); /* IE6-9 */
	}
	&:active {
		color: $white;
		text-decoration: none;
		background: #E54E40; /* Old browsers */
		background: -moz-linear-gradient(left,  #E54E40 0%, #e5404e 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left,  #E54E40 0%,#e5404e 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right,  #E54E40 0%,#e5404e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5747', endColorstr='#ff4757',GradientType=1 ); /* IE6-9 */
	}
}


//Button with grey border
.btn-grey-border {
	display: block;
	width: 100%;
	height: 38px;
	@include rounded(20px);
	@include transition(0.2s);
	background: transparent;
	font-family: $font-source;
	font-weight: 400;
	font-size: 14px;
	text-transform: uppercase;
	text-align: center;
	color: #666666;
	padding: 1px 0 0 0;
	border: 1px solid #b3b3b3;
	&,
	&:link,
	&:visited {

	}
	&:hover,
	&:focus {
		background-position: 0 40px;
		color: $white;
		text-decoration: none;
		border: 1px solid #ff5747;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff5747+0,ff4757+100 */
		background: #ff5747; /* Old browsers */
		background: -moz-linear-gradient(left,  #ff5747 0%, #ff4757 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left,  #ff5747 0%,#ff4757 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right,  #ff5747 0%,#ff4757 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5747', endColorstr='#ff4757',GradientType=1 ); /* IE6-9 */
	}
	&:active {
		color: $white;
		text-decoration: none;
		background: #E54E40; /* Old browsers */
		background: -moz-linear-gradient(left,  #E54E40 0%, #e5404e 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left,  #E54E40 0%,#e5404e 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right,  #E54E40 0%,#e5404e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5747', endColorstr='#ff4757',GradientType=1 ); /* IE6-9 */
	}
}

.btn-white-border {
	display: block;
	width: 100%;
	height: 38px;
	line-height: 38px !important;
	@include rounded(20px);
	@include transition(0.2s);
	background: transparent;
	font-family: $font-source;
	font-weight: 400;
	font-size: 14px;
	text-transform: uppercase;
	text-align: center;
	color: #fff;
	padding: 1px 0 0 0;
	border: 1px solid #fff;
	&,
	&:link,
	&:visited {
		color: #fff;
	}
	&:hover,
	&:focus {
		color: #fff;
	}
	&:active {
		color: #fff;
	}
}

.btn-red,
.btn-facebook,
.btn-white {
	display: block;
	width: 100%;
	height: 40px;
	@include rounded(20px);
	@include transition(0.2s);
	position: relative;
	&, &:link, &:visited {
		font-family: $font-source;
		font-weight: 400;
		font-size: 14px;
		text-transform: uppercase;
		text-align: center;
		color: $white;
		padding: 0;
		line-height: 38px;
		span {
			width: 80%;
			top: -1px;
			left: 10%;
			position: absolute;
			height: 40px;
			z-index: 0;
			@include rounded(20px);
			display: block;
		}
	}
	&:hover, &:focus {
		background-position: 0 40px;
		color: $white;
		text-decoration: none;
		span {
			display: none;
		}
	}
	&:active {
		color: $white;
		text-decoration: none;
	}
}

.btn-red {
	&, &:link, &:visited {
		background: #ff5747; /* Old browsers */
		background: -moz-linear-gradient(left,  #ff5747 0%, #ff4757 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left,  #ff5747 0%,#ff4757 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right,  #ff5747 0%,#ff4757 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5747', endColorstr='#ff4757',GradientType=1 ); /* IE6-9 */
		span {
			-webkit-box-shadow: 0px 4px 10px 0px rgba(255, 77, 51, 0.5);
			-moz-box-shadow: 0px 4px 10px 0px rgba(255, 77, 51, 0.5);
			box-shadow: 0px 4px 10px 0px rgba(255, 77, 51, 0.5);
		}
	}
	&:hover, &:focus {
		background: #ff5747; /* Old browsers */
		background: -moz-linear-gradient(left,  #ff5747 0%, #ff4757 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left,  #ff5747 0%,#ff4757 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right,  #ff5747 0%,#ff4757 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5747', endColorstr='#ff4757',GradientType=1 ); /* IE6-9 */
	}
	&:active {
		background: #E54E40; /* Old browsers */
		background: -moz-linear-gradient(left,  #E54E40 0%, #e5404e 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left,  #E54E40 0%,#e5404e 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right,  #E54E40 0%,#e5404e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5747', endColorstr='#ff4757',GradientType=1 ); /* IE6-9 */
	}
}

.btn-white {
	&, &:link, &:visited, &:hover, &:focus, &:active {
		background: #fff; /* Old browsers */
		color: #E54E40 !important;
	}
}

.btn-facebook {
	&, &:link, &:visited {
		background: #3b6298; /* Old browsers */
		background: -moz-linear-gradient(left,  #3b6298 0%, #3b5198 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left,  #3b6298 0%,#3b5198 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right,  #3b6298 0%,#3b5198 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b6298', endColorstr='#3b5198',GradientType=1 ); /* IE6-9 */
	}
	&:hover, &:focus {
		background: #3b6298; /* Old browsers */
		background: -moz-linear-gradient(left,  #3b5198 0%, #3b6298 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left,  #3b5198 0%,#3b6298 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right,  #3b5198 0%,#3b6298 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b5198', endColorstr='#3b6298',GradientType=1 ); /* IE6-9 */
	}
	&:active {
		background: #3b5198;
	}
}