/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 18px;
    input {
        display: none;
        &:checked + .slider {
            background-color: #ff4747;
        }
        &:focus + .slider {
            box-shadow: 0 0 1px #ff4747;
        }
        &:checked + .slider:before {
            -webkit-transform: translateX(10px);
            -ms-transform: translateX(10px);
            transform: translateX(10px);
        }
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        -webkit-transition: .4s;
        transition: .4s;
        &:before {
            position: absolute;
            content: "";
            height: 18px;
            width: 18px;
            left: 0px;
            bottom: 0px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }
        &.round {
            border-radius: 18px;
            border: 1px solid #f1f1f1;
            &:before {
                border-radius: 50%;
                @include box-shadow(0px 1px 5px 0px rgba(0, 27, 48, 0.19));
            }
        }
    }
}