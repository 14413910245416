@media (max-width: 320px){
    .container-fluid{
        width: 320px;
    }
}


@media (min-width: 450px){
    .menu-bottom{
        &__list{
            width: 450px;
            margin: 0 auto;
        }
    }
}

.container-fluid {
    overflow: hidden;
    &.menu-bottom {
        overflow: visible !important;
    }
}

@media (max-width: $screen-xs-max) {
    .info-messages {
        .col-xs-12 {
            padding-right: 0 !important;
            padding-left: 0 !important;
            .alert {
                -webkit-border-radius: 0 !important;
                -moz-border-radius: 0 !important;
                border-radius: 0 !important;
                border-right: 0 !important;
                border-left: 0 !important;
            }
        }
    }
}