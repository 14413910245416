.o-tiles{
    margin-top: 7rem;
    float: left;
    width: 100%;
    position: relative;
}
.o-tiles.type-1 .m-tile{
    box-shadow: 0 10px 35px 0 rgba(0,0,0,.12);
    width: 92%;
    margin-left: 4%;
    height: 37rem;
    border-radius: 7px;
    padding: 2rem 12%;
    background: $white;
}
.o-content-switch{
    margin-top: 7.5rem;
}
.o-tiles-slider-wrapper{
    float: left;
    width: 100%;
    overflow: visible;
    position: relative;
    height: 33rem;
    margin-top: 5rem;
    .o-tiles-slider{
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: visible;
        top: 0;
        left: 0;
    }
}
@media (max-width: $screen-md-max){
    .o-tiles-slider-wrapper{
        margin-bottom: 5rem;
    }
    .o-tiles.type-1 .m-tile{
        width: 100%;
        margin-left: 0;
    }
}
@media (max-width: $screen-sm-max){
    body.resta{
        .part2 .o-tiles{
            margin-top: 0;
            margin-bottom: 5rem;
        }
    }
}