.landing{
    .land{
        transition: opacity .8s, transform .8s;
        opacity: 0;
        transform: translateY(10rem);
        &.land-rot1{
            transform: translateY(10rem) rotate(14deg);
        }
        &.land-rot2{
            transform: translateY(10rem) rotate(-14deg);
        }
    }
    @for $i from 1 through 7{
        .land-delay-#{$i}{
            transition-delay: ($i * .15s);
        }
    }
    &.landed .land{
        opacity: 1;
        transform: translateY(0);
    }
}
#parallax_scene{
    opacity: 0;
    transition: 2s;
    &.landed{
        opacity: 1;
    }
}
@media (max-width: $screen-xs-max){
    .landing .land, .landing .land.land-rot1, .landing .land.land-rot2{
        opacity: 1;
        transform: translate3d(0,0,0);
    }
}