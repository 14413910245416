.m-button-wrapper{
    width: 100%;
    float: left;
    text-align: left;
    &.centered{
        text-align: center;
    }
}
.o-tiles{
    .m-tile{
        text-align: center;
        position: relative;
        float: left;
        width: 100%;
        .a-image, h4, p{
            width: 100%;
            text-align: center;
            float: left;
            position: relative;
        }
        h4{
            font-size: 3.15rem;
            margin-top: 2.3rem;
        }
        p{
            font-size: 1.7rem;
            margin-top: 1.5rem;
            line-height: 1.6;
            color: $dark-gray;
        }
    }
}
.o-content-switch .m-content-switch{
    color: $white;
    border-bottom: 2px solid $white;
    opacity: .5;
    transition: .2s;
    float: left;
    width: 33.33333%;
    cursor: pointer;
    padding-bottom: 3rem;
    &:hover{
        opacity: .7;
    }
    &.active, &.active:hover{
        opacity: 1;
    }
    h4{
        font-size: 3.5rem;
        text-align: center;
    }
}
.m-images-wrapper{
    float: left;
    width: 100%;
    position: relative;
    img{
        float: left;
    }
}
.m-footer-links{
    float: left;
    a{
        color: $white;
        text-decoration: none;
        float: left;
        font-size: 1.4rem;
        letter-spacing: .03em;
        line-height: 4rem;
        font-family: $font-open-sans;
        font-weight: 300;
    }
}
.m-footer-socs{
    text-align: right;
    a{
        float: right;
        color: $white;
        text-decoration: none;
        display: block;
        height: auto;
        width: auto;
        position: relative;
        img.fb{
            height: 27px;
        }
    }
}
.o-tiles-slider{
    transition: .4s;
    .m-tile{
        box-shadow: 0 10px 35px 0 rgba(0,0,0,.12);
        width: 100%;
        height: 100%;
        border-radius: 7px;
        padding: 4rem 7%;
        background: #fcf9fa;
        .m-top{
            float: left;
            width: 100%;
            height: auto;
            h4{
                float: left;
                display: inline;
                width: auto;
                font-size: 2.7rem;
                font-weight: 400;
                margin-top: 3.3rem;
                letter-spacing: .02em;
                font-family: $font-open-sans;
            }
        }
        p{
            margin-top: 1.9rem;
            font-size: 1.8rem;
            line-height: 1.7;
            letter-spacing: .02em;
        }
    }
    .m-tile-wrapper{
        position: absolute;
        top: 0;
        height: 100%;
        @for $i from 1 through 10{
            &:nth-child(#{$i}){
                left: (($i - 1) * 42%);
            }
        }
    }
}
.m-tiles-slider-controlls{
    float: left;
    width: 100%;
    text-align: center;
    .m-controls-wrapper{
        display: inline-block;
        width: auto;
        margin-top: 35.8rem;
        button{
            background: transparent url(../imgs/icons/btn-arrow-right-black.png) no-repeat center;
            background-size: 40px 22px;
            border: none;
            -webkit-appearance: none;
            color: transparent;
            height: 8rem;
            width: 12rem;
            display: inline-block;
            float: left;
            cursor: pointer;
            transition: .2s;
            &.back{
                transform: rotate(180deg);
            }
            &:hover{
                opacity: .35;
            }
        }
    }
}
footer .m-button-links{
    border-bottom: 1px solid #6c6c6c;
    height: auto;
    position: relative;
    float: left;
    width: 100%;
    padding: 0 4rem 4.5rem;
    a{
        border: 1px solid #6c6c6c;
        line-height: 5rem;
        padding: 0 2rem;
        font-size: 1.4rem;
        border-radius: 4px;
        display: block;
        float: left;
        width: 45%;
        position: relative;
        &:first-child{
            margin-right: 10%;
        }
        &:after{
            display: block;
            position: absolute;
            content: '';
            top: 0;
            right: 2rem;
            bottom: 0;
            height: 1rem;
            width: 1rem;
            margin: auto;
            border-right: 1px solid #6c6c6c;
            border-bottom: 1px solid #6c6c6c;
            transform: rotate(-45deg);
        }
    }
}

@media (max-width: $screen-md-max){
    .o-tiles .m-tile h4{
        margin-top: 0;
    }
    .o-tiles-slider{
        .m-tile-wrapper{
            position: absolute;
            top: 0;
            height: 100%;
            @for $i from 1 through 10{
                &:nth-child(#{$i}){
                    left: (($i - 1) * 59%);
                }
            }
        }
    }
    .m-footer-links{
        a{
            line-height: 2rem;
        }
    }
    body.resta {
        .part1 .o-tiles{
            padding: 0 10%;
            margin-top: 2rem;
            .m-tile{
                box-shadow: 0 10px 35px 0 rgba(0,0,0,.12);
                width: 96%;
                margin: 6rem 2% 0;
                height: 37rem;
                border-radius: 7px;
                padding: 2rem 12%;
            }
        }
        .o-content-switch .m-content-switch{
            img{
                transform: scale(.9);
            }
            h4{
                font-size: 3rem;
            }
        }
        .part2 .o-tiles .m-tile{
            margin-top: 5rem;
            padding: 0;
            height: auto;
        }
    }
    footer {
        nav {
            &.visible-xs {
                padding-top: 1.5rem;
            }
        }
    }
    .m-footer-links{
        text-align: center;
        padding-top: 1.5rem;
        a{
            font-size: 1.2rem;
            float: none;
        }
        .a-separator{
            display: inline-block;
            float: none;
            height: .8rem;
        }
    }
}
@media (max-width: $screen-sm-max){
    body.resta{
        .part1{
            .o-tiles{
                padding: 0;
                width: 100%;
                margin: 0 0 2rem;
                .m-tile{
                    padding: 2rem 8%;
                }
            }
        }
        .o-content-switch .m-content-switch{
            h4{
                font-size: 2.7rem;
                padding: 0 10%;
            }
        }
    }
    .o-tiles-slider{
        .m-tile-wrapper{
            position: absolute;
            top: 0;
            height: 100%;
            @for $i from 1 through 10{
                &:nth-child(#{$i}){
                    left: (($i - 1) * 67%);
                }
            }
        }
    }
}
@media (max-width: $screen-xs-max){
    body.hp{
        .part1{
            .o-tiles{
                margin-top: 2rem;
                .m-tile{
                    margin-top: 5rem;
                    height: auto;
                    padding-bottom: 4rem;
                }
            }
        }
    }
    body.resta{
        .top{
            .m-button-wrapper{
                margin-top: 5rem;
            }
        }
        .part1{
            .o-tiles .m-tile{
                width: 100%;
                margin: 5rem 0 0;
                height: auto;
                padding-bottom: 4rem;
            }
        }
        .o-content{
            border-bottom: 1px solid #5c5d60;
            position: relative;
            float: left;
        }
        .m-content-switch{
            position: relative;
            float: left;
            width: 100%;
            border-bottom: 1px solid #5c5d60;
            padding: 2rem 0;
            h4{
                font-size: 2rem;
                padding: 0;
                float: left;
                position: relative;
                width: auto;
            }
            .a-image{
                height: 4rem;
                width: 4rem;
                float: left;
                position: relative;
                margin-right: 2rem;
                img{
                    height: 100%;
                    width: auto;
                }
            }
            &:after, &:before{
                position: absolute;
                display: block;
                content: '';
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                background: $white;
            }
            &:after{
                height: 1px;
                width: 13px;
                right: 0;
            }
            &:before{
                height: 13px;
                width: 1px;
                right: 6px;
            }
            &.active{
                &:before, &:after{
                    transform: rotate(45deg);
                }
            }
        }
        .part2{
            .o-tiles .m-tile{
                margin-top: 10rem;
                &.first{
                    margin-top: 4rem;
                }
            }
        }
    }
    footer .m-button-links{
        a{
            width: 100%;
            margin: 0;
            &:first-child{
                border-bottom: 0;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
            &:last-child{
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }
    .o-tiles-slider{
        .m-tile-wrapper{
            position: absolute;
            top: 0;
            height: 100%;
            @for $i from 1 through 10{
                &:nth-child(#{$i}){
                    left: (($i - 1) * 95%);
                }
            }
            .m-tile{
                .m-top{
                    .a-image{
                        margin-right: 1rem;
                    }
                    h4{
                        font-size: 1.9rem;
                        margin-top: 2.5rem;
                    }
                }
                p{
                    font-size: 1.5rem;
                }
            }
        }
    }
}
@media (max-width: $screen-xs-max) and (orientation: landscape){
    body.resta{
        .top{
            .m-button-wrapper{
                margin-top: 2rem;
            }
        }
    }
}